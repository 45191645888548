<template>
    <div>
        <b-card>
            <b-card-header header-class="pt-0">
                <div
                    class="d-flex justify-content-between align-items-center w-100"
                >
                    <h4>تفاصيل الكورس {{ courseDetailsDto.name }}</h4>
                    <b-form-radio-group
                        id="radio-group-2"
                        v-model="courseDetailsDto.isFree"
                        name="radio-sub-component"
                    >
                        <b-form-radio :value="true">مجاني</b-form-radio>
                        <b-form-radio :value="false">مدفوع</b-form-radio>
                    </b-form-radio-group>
                </div>
            </b-card-header>

            <b-card-body>
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col lg="4">
                            <ek-input-text
                                name="name"
                                label=" اسم الكورس "
                                placeholder="ادخل اسم الكورس"
                                v-model="courseDetailsDto.name"
                            >
                            </ek-input-text>
                            <b-row no-gutters>
                                <b-col lg="6" md="12">
                                    <ek-input-select
                                        :options="years"
                                        v-model="courseDetailsDto.yearId"
                                        placeholder="السنة"
                                        name=" اختر السنة"
                                        label="السنة"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'السنة مطلوبة',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                                <b-col lg="6" md="12" class="pl-2">
                                    <ek-input-select
                                        :options="semesters"
                                        placeholder="اختر الفصل"
                                        name=" اختر الفصل"
                                        label="الفصل"
                                        v-model="courseDetailsDto.semesterId"
                                        :rules="[
                                            {
                                                type: 'required',
                                                message: 'الفصل مطلوب',
                                            },
                                        ]"
                                    >
                                    </ek-input-select>
                                </b-col>
                            </b-row>
                            <ek-input-text
                                name="name"
                                label=" ترتيب الكورس "
                                v-model="courseDetailsDto.order"
                                placeholder="ترتيب الكورس"
                            >
                            </ek-input-text>
                            <ek-input-text
                                type="number"
                                readonly
                                name="name"
                                label=" عدد الدروس "
                                placeholder="عدد الدروس"
                                v-model="courseDetailsDto.lessonCount"
                            >
                            </ek-input-text>
                        </b-col>

                        <b-col lg="4">
                            <!-- جامعة كلية فرع  -->
                            <ek-input-select
                                name="الفرع"
                                label=" تابع ل جامعة - كلية - فرع "
                                placeholder="جامعة - كلية - فرع"
                                :options="departmentsSubjectsOptions"
                                v-model="courseDetailsDto.departmentId"
                                @change="resetSubject"
                            >
                            </ek-input-select>

                            <ek-input-select
                                name="المادة"
                                v-model="courseDetailsDto.subjectId"
                                v-if="courseDetailsDto.departmentId"
                                label=" تابع ل سنة - فصل - مادة "
                                placeholder="تابع ل سنة - فصل - مادة "
                                :options="
                                    departmentsSubjectsOptions.find(
                                        (d) =>
                                            d.id ==
                                            courseDetailsDto.departmentId
                                    ).subjects
                                "
                            >
                            </ek-input-select>

                            <ek-input-select
                                :options="settingDto.teachers"
                                clearable
                                placeholder="مدرس الكورس"
                                name="مدرس الكورس"
                                label="مدرس الكورس"
                                v-model="courseDetailsDto.teacherId"
                            >
                            </ek-input-select>

                            <ek-input-text
                                type="number"
                                readonly
                                name="name"
                                label=" عدد الإشتراكات "
                                placeholder="عدد الإشتراكات"
                                v-model="courseDetailsDto.codesCount"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col lg="4" class="position-relative">
                            <b-dropdown
                                size="lg"
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                                class="position-absolute"
                            >
                                <template #button-content>
                                    <unicon
                                        name="ellipsis-v"
                                        fill="#2EC4B6"
                                    ></unicon>
                                </template>
                                <b-dropdown-item
                                    href="#"
                                    ref="myUploader"
                                    @click="fileWindowApi(onUpload)"
                                >
                                    <unicon
                                        name="edit-alt"
                                        fill="#2EC4B6"
                                        style="width: 25px"
                                    ></unicon>
                                    <span>تعديل الصورة</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <img
                                class="h-100 w-100 rounded"
                                style="object-fit: cover"
                                :src="
                                    $store.getters['app/domainHost'] +
                                    '/' +
                                    courseDetailsDto.url
                                "
                                alt=""
                            />
                        </b-col>
                        <b-col lg="12">
                            <ek-input-textarea
                                name="name"
                                label="شرح الكورس"
                                placeholder="شرح الكورس"
                                v-model="courseDetailsDto.description"
                            >
                            </ek-input-textarea>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </b-card-body>
        </b-card>

        <template v-if="getUserRoles() != Teacher">
            <div class="d-flex justify-content-end align-items-center">
                <ek-input-text
                    v-model="searchLessonVal"
                    name="ابحث عن درس محدد"
                    placeholder="ابحث عن درس محدد"
                    @input="filterSearch"
                >
                </ek-input-text>
                <AddLessons
                    v-if="
                        getUserRoles() == Admin ||
                        getUserRoles() == SuperAdmin ||
                        getUserRoles() == DataEntry
                    "
                    @fillSelectLesson="
                        $store.commit('Fill_Course_Select', courseDetailsDto)
                    "
                    class="ml-2"
                ></AddLessons>
                <!-- <b-button variant="primary" class="mx-2">درس جديد</b-button> -->
            </div>
            <div
                v-if="
                    getUserRoles() == Admin ||
                    getUserRoles() == SuperAdmin ||
                    getUserRoles() == DataEntry
                "
            >
                <span>
                    <div class="d-flex">
                        <unicon
                            class="pr-1"
                            fill="gray"
                            name="bookmark"
                        ></unicon>
                        <h4>
                            محتوى الكورس ({{ courseDetailsDto.lessons.length }})
                            دروس
                        </h4>
                    </div>
                </span>
                <ek-table
                    :columns="columnsLessons"
                    :items="filterLessons"
                    @details="detailsLesson"
                    @delete-selected="deleteLesson"
                >
                    <template slot="items.dateCreated" slot-scope="{ value }">
                        {{ new Date(value).toLocaleDateString() }}
                    </template>
                    <template slot="items.url" slot-scope="{ value }">
                        <b-button
                            @click="downloadFile(value)"
                            variant="primary"
                        >
                            تحميل
                        </b-button>
                    </template>
                </ek-table>
            </div>
        </template>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { fileWindowApi } from "@core/utils/uploader";
import AddLessons from "@admin/lessons/components/AddLesson.vue";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin, DataEntry, Teacher } from "@/router";

export default {
    props: {
        id: String,
    },
    components: {
        AddLessons,
    },
    data() {
        return {
            SuperAdmin,
            Admin,
            DataEntry,
            Teacher,
            searchLessonVal: "",
            columnsLessons: [
                {
                    label: "اسم الدرس ",
                    field: "name",
                },
                {
                    label: "ترتيب الدرس",
                    field: "order",
                },
                {
                    label: "تحميل",
                    field: "url",
                },
                {
                    label: "نوع الدرس",
                    field: "lessonType",
                },
                {
                    label: "تاريخ الاضافة",
                    field: "dateCreated",
                },
                {
                    label: "تفاصيل",
                    field: "details",
                },
            ],
        };
    },
    computed: {
        ...mapState({
            settingDto: ({ settings }) => settings.settingDto,
            courseDetailsDto: ({ courses }) => courses.courseDetailsDto,
            universityNames: (state) => state.university.universityName,
            years: (state) => state.settings.yearList,
            semesters: (state) => state.settings.semesterList,
            departmentsSubjectsOptions: ({ courses }) =>
                courses.departmentsSubjectsOptions,
        }),
        ...mapGetters(["filterLessons"]),
    },
    methods: {
        ...mapActions([
            "addFileCourse",
            "getCourseDetails",
            "getNamesUniversity",
            "getSettingYear",
            "getSettingSemester",
            "getSettings",
            "getDepartmentsWithSubjects",
            "deleteLesson",
            "getFileLink",
        ]),
        getUserRoles,
        fileWindowApi,
        onUpload(e) {
            if (e.file) {
                this.addFileCourse({ ...e, folderName: "Profile" }).then(
                    (res) => {
                        this.courseDetailsDto.url = res.data;
                    }
                );
            }
        },

        filterSearch() {
            this.$store.commit("Filter_Lessons", this.searchLessonVal);
        },
        detailsLesson({ row }) {
            this.$router.push({ path: `/admin/lessons/${row.id}` });
        },
        resetSubject() {
            this.courseDetailsDto.subjectId = "";
            // this.subjectDetailDto.departmentId = "";
        },

        downloadFile(fileUrl) {
            console.log(`Download`, fileUrl);
            this.getFileLink(fileUrl).then((fl) => {
                console.log(fl);
                const link = document.createElement("a");

                link.href = fl;

                link.setAttribute("download", fl); //any other extensio

                document.body.appendChild(link);

                link.click();

                link.remove();
            });
        },
    },

    created() {
        this.getDepartmentsWithSubjects("0"); // StudentType == 0
        this.getCourseDetails(this.id).then(() => {
            this.$store.commit("Fill_Course_Select", this.courseDetailsDto);
        });
        this.getSettings();
        this.getNamesUniversity("0");
        this.getSettingYear();
        this.getSettingSemester();
    },
    beforeDestroy() {
        this.$store.commit("Reset_Lesson");
    },
};
</script>
<style lang="scss">
.dropdown.b-dropdown {
    right: 0 !important;
}
</style>
